<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">ยืนยันการรวมบิล {{ form.invoiceNo }}</div>
      </template>
      <p class="text-center label"
        >กรอก Invoice No ที่ต้องการรวมกับ Invioce No {{ form.invoiceNo }}</p
      >
      <div class="d-flex justify-content-center mt-3">
        <div class="w-75">
          <InputText
            v-model="form.refNo"
            textFloat="Ref no"
            placeholder="Ref no"
            type="text"
            name="refNo"
            className="mb-2"
            isRequired
            :isValidate="$v.form.refNo.$error"
            :v="$v.form.refNo"
          />
          <InputText
            v-model="form.saleId"
            textFloat="Sale ID"
            placeholder="Sale ID"
            type="text"
            name="saleId"
            className="mb-2"
            isRequired
            :isValidate="$v.form.saleId.$error"
            :v="$v.form.saleId"
          />
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    InputSelect,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { id: 0, invoiceNo: "", refNo: "", saleId: "" },
    };
  },
  validations() {
    return {
      form: {
        refNo: {
          required,
        },
        saleId: {
          required,
        },
      },
    };
  },
  methods: {
    async show(item) {
      this.form.id = item.id;
      this.form.invoiceNo = item.invoice_no;
      this.form.refNo = "";
      this.form.saleId = "";

      this.$v.form.$reset();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {
        invoiceNo_A: this.form.invoiceNo,
        invoiceNo_B: this.form.refNo,
        sales_id: this.form.saleId,
        branch_id: this.branchId,
      };
      await this.axios
        .post(`${this.$baseUrl}/transaction/MergePoint`, body)
        .then(async(data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$emit("reloadDataTable");
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.label {
  color: #333 !important;
  font-weight: bold !important;
}
</style>
